import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';  //REMOVE IT FOR OTHER TOOLS - ONLY USE FOR AUTOGRAPH

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import TypeSignature from './components/TypeSignature';
import AiTypeSignature from './components/AiTypeSignature';
import DrawSignature from './components/DrawSignature';
import Welcome from './components/Welcome';

import AutoGraph from './components/AutoGraph/AutoGraph';
import AutoGraphV2 from './components/AutoGraph/AutoGraphV2';
import AutoGraphV3 from './components/AutoGraph/AutoGraphV3';

function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
        <Routes>
          {<Route path="/" element={<Welcome />}></Route>}
          {<Route path="/aiSignatures" element={<AiTypeSignature />}></Route>}
          {<Route path="/typeSignatures" element={<TypeSignature />}></Route>}
          {<Route path="/drawSignatures" element={<DrawSignature />}></Route>}
          {<Route path="/drawSignatures" element={<DrawSignature />}></Route>}
          {<Route path="/autograph" element={<AutoGraphV3 />}></Route>}


        </Routes>
      </BrowserRouter>
      
      {/*
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<Welcome />}></Route>
            <Route path="/type-signature" element={<TypeSignature />}></Route>
            <Route path="/draw-signature" element={<DrawSignature />}></Route>
          </Routes>
        </div>
      </BrowserRouter>
    */}
    </div>
  );
}

export default App;


