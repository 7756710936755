
import { react, useEffect, useState } from 'react';
import ListSignatures from './AiTypeSignature/ListSignatures';
import ColorPicker from './ColorPicker';

/**SLIDER START */
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const canvasCss = {
    border: "1px black solid"
}

const AiTypeSignature = () => {

    const [name, setName] = useState("My Ai Signatures");
    const [penColor, setPenColor] = useState("#000000");
    const [fontSize, setFontSize] = useState(60);
    const [fontWeight, setFontWeight] = useState(400);
    const [category, setCategory] = useState("handwriting");


    const [listingType, setListingType] = useState("GRID")  //GRID, LIST

    const handlePenColor = (color) => { setPenColor(color.hex); }

    const categrories = {
        "handwriting": "Handwriting", "cursive": "Cursive", "stylish": "Stylish",
        "modern": "Modern", "fun": "Fun", "formal": "Formal", "professional": "Professional", "traditional": "Traditional",
    }

    const fontFamiliesList = {
        "Senja Santuy": { "fontSizeScale": 1.0, fontWeightScale: 1, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Hoarsely Single Line": { "fontSizeScale": 1.0, fontWeightScale: 1.5, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Sansilk": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Really Free": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Free for Palestine": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Barokah Signature": { "fontSizeScale": 0.4, fontWeightScale: 1.0, extraHeightScaleForCanvas: 3.0, extraYForfillTextScaling: 1.1 },
        "Travel November": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Sundiary": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Salonica": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Scriptina Pro": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Rosabelia": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Astagina Signature": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Batfide Signature": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Rightman Signature": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Slow Smoke": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Endalmin": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },

        /** CURSIVE **/
        "Allura": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Sacramento": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "5th Grade Cursive": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Great Vibes": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Adine Kirnberg": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Parisienne": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Honey Script": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Mrs Saint Delafield": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },

        //STYLISH
        "BrockScript": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "EuphoriaScript-Regular": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "FlaemischeKanzleischrift": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Gianna": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Qwigley": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },

        //TRADITIONAL
        "BantengStory": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Famulred": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Italianno": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Adusian Signature": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },

        //PROFESSIONAL 
        "ChanticleerRomanNF": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "engebrechtreRg": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Flanella": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "MrBedfort-Regular": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Rhesmanisa": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Bona Nova": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },

        //FORMAL
        "Formal Script": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Calligraffitti": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Rochester": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },

        //MODERN
        "Arizonia": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Euphoria Script": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Stalemate": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "Attentica 4F": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
        "League Script": { "fontSizeScale": 1.0, fontWeightScale: 1.0, extraHeightScaleForCanvas: 0, extraYForfillTextScaling: 0 },
         
        //Fun  
        "Penguin Attack": { "fontSizeScale": 1.0, fontWeightScale: 1.0 , extraHeightScaleForCanvas : 0, extraYForfillTextScaling : 0},
        "Minya Nouvelle": { "fontSizeScale": 1.0, fontWeightScale: 1.0 , extraHeightScaleForCanvas : 0, extraYForfillTextScaling : 0},
        "Butterfly Kids": { "fontSizeScale": 1.0, fontWeightScale: 1.0 , extraHeightScaleForCanvas : 0, extraYForfillTextScaling : 0},
        "Mystery Quest": { "fontSizeScale": 1.0, fontWeightScale: 1.0 , extraHeightScaleForCanvas : 0, extraYForfillTextScaling : 0},
        "Henny Penny": { "fontSizeScale": 1.0, fontWeightScale: 1.0 , extraHeightScaleForCanvas : 0, extraYForfillTextScaling : 0},
        "Life Savers": { "fontSizeScale": 1.0, fontWeightScale: 1.0 , extraHeightScaleForCanvas : 0, extraYForfillTextScaling : 0},
    }


    //UPDATE SELECTED CATEGORY
    const updateCategory = (selectedCategory) => {
        setCategory(selectedCategory);
    }

    /** CONVERT TXT INTO IMAGES AND DOWNLOAD */
    const downloadSignature = (fontName, fontSizeScale) => {
        let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
        let newFontSize = fontSize * fontSizeScale;

        var font = `${fontWeight} ${newFontSize}px ${fontName}`;
        canvasTxt.font = font;
        //let metrics = canvasTxt.measureText(name);

        canvasTxt.canvas.width = parseInt(canvasTxt.measureText(name).width) + 50;
        let extraHeight = newFontSize * fontFamiliesList[fontName].extraHeightScaleForCanvas;
        extraHeight = extraHeight < 50 ? 50 : extraHeight;

        //CHECK IF LOWERCASE  G,J, P, Q Y ARE ALSO PART OF THE STRING
        if (name.includes("g") || name.includes("j") || name.includes("p") || name.includes("q") || name.includes("y")) { extraHeight = parseInt(extraHeight) + 20; }

        canvasTxt.canvas.height = parseInt(newFontSize) + extraHeight; //metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

        canvasTxt.font = font;
        canvasTxt.fillStyle = penColor;  // set the color only now
        let extraYindex = newFontSize * fontFamiliesList[fontName].extraYForfillTextScaling;
        canvasTxt.fillText(name, 10, parseInt(newFontSize) + extraYindex);

        var a = document.createElement("a"); //Create <a>
        a.href = canvasTxt.canvas.toDataURL(); //Image Base64 Goes here
        a.download = "Signature.png"; //File name Here
        a.click();

    }

    return (
        <>
            <div className='typeSignatureContainer' >
                <div className='row'>

                    <div className='col-sm-12'>
                        <div className='typeSignaturePadContainer' >
                            <div className='typeSignaturePadWrapper  mt-5'>
                                <div className='mt-1 mb-3'>
                                    <div className='typeSignatureFieldWrapper'>
                                        <input type="text"
                                            maxLength={25}
                                            className='typeSignatureField'
                                            placeholder='Type your name '
                                            name="typeSignatureInputField"
                                            onChange={(e) => { setName(e.target.value); }}
                                        />
                                    </div>
                                </div>

                                { /** ACTIONS START */}
                                <div className='typeSignatureActionsContainer'>
                                    <div className='typeSignatureActionsSection1'>
                                        <div className="typeSignatureActionsSection1Child1">
                                            <ColorPicker penColor={penColor} handlePenColor={handlePenColor} />
                                        </div>
                                        <div className='flexBreakSmallScreen'></div>
                                        <div className='typeSignatureActionsSection1Child2'>
                                            <div className="rbtn-group" role="group" aria-label="Basic example">
                                                <button type="button" className={listingType == "GRID" ? "rbtn rbtn-secondary rbtn-sm rgridButton active" : "rbtn rbtn-secondary rbtn-sm rgridButton "} onClick={() => { setListingType("GRID") }}>Grid</button>
                                                <button type="button" className={listingType == "LIST" ? "rbtn rbtn-secondary rbtn-sm rlistButton active" : "rbtn rbtn-secondary rbtn-sm rlistButton"} onClick={() => { setListingType("LIST") }}>List</button>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='typeSignatureActionsSection2'>
                                        <div className='sliderCss'>
                                            <label style={{ fontSize: "12px" }}>FONT SIZE</label>
                                            <Slider
                                                defaultValue={fontSize}
                                                min={12}
                                                max={160}
                                                step={5}
                                                onChange={(value) => { setFontSize(value) }}
                                            />
                                        </div>
                                    </div>
                                    <div className='typeSignatureActionsSection2'>
                                        <div className='sliderCss'>
                                            <label style={{ fontSize: "12px" }}>Font Weight</label>
                                            <Slider
                                                defaultValue={fontSize}
                                                min={100}
                                                max={1000}
                                                step={100}
                                                onChange={(value) => { setFontWeight(value) }}
                                            />
                                        </div>
                                    </div>

                                    {/** FONT SELECTION   */}
                                    <div className='mt-3'>
                                        <label style={{ fontSize: "12px", marginBottom: "12px" }}>Select Style</label>

                                        <div className="ai-categories-box">
                                            {
                                                Object.keys(categrories).map(singleKey => {
                                                    return <>
                                                        <div className='ai-btn-box'>
                                                            <button type="button" data-category={singleKey} className={singleKey === category ? "rbtn rbtn-sm ai-btn  active " : "rbtn ai-btn rbtn-sm  "} onClick={(e) => { updateCategory(e.target.dataset.category); }}> {categrories[singleKey]} </button>
                                                        </div>
                                                    </>
                                                })
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='typeSignatureContainer2'>
                <div className='row mt-5'>
                    {
                        name.length > 0 ? <ListSignatures category={category} name={name} color={penColor} listingType={listingType} fontSize={fontSize} fontWeight={fontWeight} callback={downloadSignature} /> : ""
                    }

                </div>
            </div>

            <canvas id="canvasComponent" style={{ display: "none" }} />

        </>
    );
}

export default AiTypeSignature;