
import { react, useState } from 'react';
import ListSignatures from './TypeSignature/ListSignatures';
import ColorPicker from './ColorPicker';

/**SLIDER START */
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const canvasCss = {
    border: "1px black solid"
}

const TypeSignature = () => {

    const [name, setName] = useState("");
    const [penColor, setPenColor] = useState("#000000");
    const [fontSize, setFontSize] = useState(60);
    const [fontWeight, setFontWeight] = useState(400);

    const [listingType, setListingType] = useState("GRID")  //GRID, LIST

    const handlePenColor = (color) => { setPenColor(color.hex); }
    const updateListingType = () => {

    }

    const downloadSignature = (fontName) => {
        console.log("FONT NAME from parent class: ", fontName);
        let canvasTxt = document.getElementById("canvasComponent").getContext("2d");

        var font = `${fontWeight} ${fontSize}px ${fontName}`;
        canvasTxt.font = font;
        canvasTxt.canvas.width = parseInt(canvasTxt.measureText(name).width) + 50;
        canvasTxt.canvas.height = parseInt(fontSize) + 20;
        canvasTxt.font = font;
        canvasTxt.fillStyle = penColor;  // set the color only now
        canvasTxt.fillText(name, 5, parseInt(fontSize));
        canvasTxt.fillRect(200, 150, 150, 75)
        console.log(canvasTxt.canvas.toDataURL());

        var a = document.createElement("a"); //Create <a>
        a.href = canvasTxt.canvas.toDataURL(); //Image Base64 Goes here
        a.download = "Signature.png"; //File name Here
        a.click();

    }

    return (
        <>
            <div className='typeSignatureContainer' >
                <div className='row'>
                     
                    <div className='col-sm-12'>
                        <div className='typeSignaturePadContainer' >
                            <div className='typeSignaturePadWrapper  mt-5'>
                                <div className='mt-1 mb-3'>
                                    <div className='typeSignatureFieldWrapper'>
                                        <input type="text"
                                            maxLength={25}
                                            className='typeSignatureField'
                                            placeholder='Type your name '
                                            name="typeSignatureInputField"
                                            onChange={(e) => { setName(e.target.value); }}
                                        />
                                    </div>
                                </div>

                                { /** ACTIONS START */}
                                <div className='typeSignatureActionsContainer'>
                                    <div className='typeSignatureActionsSection1'>
                                        <div className="typeSignatureActionsSection1Child1">
                                            <ColorPicker penColor={penColor} handlePenColor={handlePenColor} />
                                        </div>
                                        <div className='flexBreakSmallScreen'></div>
                                        <div className='typeSignatureActionsSection1Child2'>
                                            <div className="rbtn-group" role="group" aria-label="Basic example">
                                                <button type="button" className={listingType == "GRID" ? "rbtn rbtn-secondary rbtn-sm rgridButton active" : "rbtn rbtn-secondary rbtn-sm rgridButton "} onClick={() => { setListingType("GRID") }}>Grid</button>
                                                <button type="button" className={listingType == "LIST" ? "rbtn rbtn-secondary rbtn-sm rlistButton active" : "rbtn rbtn-secondary rbtn-sm rlistButton"} onClick={() => { setListingType("LIST") }}>List</button>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='typeSignatureActionsSection2'>
                                        <div className='sliderCss'>
                                            <label style={{fontSize: "12px"}}>Font Size</label>
                                            <Slider
                                                defaultValue={fontSize}
                                                min={12}
                                                max={80}
                                                step={5}
                                                onChange={(value) => { setFontSize(value) }}
                                            />
                                        </div>
                                    </div>
                                    <div className='typeSignatureActionsSection2'>
                                        <div className='sliderCss'>
                                            <label style={{fontSize: "12px"}}>Font Weight</label>
                                            <Slider
                                                defaultValue={fontSize}
                                                min={100}
                                                max={1000}
                                                step={100}
                                                onChange={(value) => { setFontWeight(value) }}
                                            />
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='typeSignatureContainer2'>
                <div className='row mt-5'>
                    {
                        name.length > 0 ? <ListSignatures name={name} color={penColor} listingType={listingType} fontSize={fontSize} fontWeight={fontWeight} callback={downloadSignature} /> : ""
                    }

                </div>
            </div>

            <canvas id="canvasComponent" style={{ display: "none" }} />

        </>
    );
}

export default TypeSignature;