
import { react, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom'
import SignatureCanvas from 'react-signature-canvas'
import useWindowDimensions from '../hooks/useWindowDimensions';

import ColorPicker from './ColorPicker';
//import useWindowDimensions from '../hooks/useWindowDimensions';


/**SLIDER START */
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const DrawSignature = () => {
    const [penColor, setPenColor] = useState("#000");
    const [penWidth, setPenWidth] = useState(1);
    const [actionButtonsStatus, setActionButtonsStatus] = useState("disabled");
    const { height, width } = useWindowDimensions();
    const [canvasWidth, setCanvasWidth] = useState(800);
    let canvasRef = useRef();


    useEffect(() => {
        console.log("WIDT::", width);
        if (width < 600) {
            setCanvasWidth(350);
            canvasRef.props.canvasProps.width = 350;
            canvasRef.props.canvasProps.height = 300;
        }
        else if (width <= 850) {
            setCanvasWidth(700);
            canvasRef.props.canvasProps.width = 700;
            canvasRef.props.canvasProps.height = 300;
        }
        else if (width > 850) {
            setCanvasWidth(800);
            canvasRef.props.canvasProps.width = 800;
            canvasRef.props.canvasProps.height = 300;
        }
    }, [width])

    const handlePenColor = (color) => { setPenColor(color.hex); }

    const actionHandler = (action, value = 0) => {
        switch (action) {
            case "isCanvasEmpty":
                if (!canvasRef.isEmpty()) { setActionButtonsStatus(""); }
                break;
            case "clearCanvas":
                canvasRef.clear();
                setActionButtonsStatus("disabled")
                break;
            case "downloadSignature":
                if (!canvasRef.isEmpty()) {
                    var a = document.createElement("a"); //Create <a>
                    a.href = canvasRef.getTrimmedCanvas().toDataURL('image/png'); //Image Base64 Goes here
                    a.download = "Signature.png"; //File name Here
                    a.click();
                }
                break;
            case "penWidthUpdated":
                setPenWidth(parseInt(value));
                break;
        }
    }

    return (
        <>
            <div className='drawSignaturePadContainer'>
                <div className='drawSignaturePadWrappper'>
                    <div className='signaturePadSection'>
                        <div className="rcard  " style={{ width: `${canvasWidth}px`, height: "300px" }}>
                            <SignatureCanvas
                                ref={(ref) => { canvasRef = ref }}
                                penColor={penColor}
                                throttle={16}
                                clearOnResize={true}
                                minWidth={penWidth}
                                maxWidth={penWidth}
                                canvasProps={{
                                    width: canvasWidth,
                                    height: 300,
                                    className: 'sigCanvas'
                                }}
                                onEnd={() => { actionHandler("isCanvasEmpty") }}
                            />
                        </div>
                    </div>


                    { /**  parent wrapper of all actions */}
                    <div className='actionsContainer'>
                        <div className='actionsWrapper'>
                        { /** FEATURES - PEN COLOR, PEN WIDTH, ANGLE  */}
                        <div style={{alignSelf: "center" }}>
                            <div className='otherActionsWrapper' >
                                <div  className="colorPickerSection">
                                    <ColorPicker penColor={penColor} handlePenColor={handlePenColor} />
                                </div>
                                <div  className="penWidthSection">
                                    <label style={{fontSize:"12px"}}>Width</label>
                                    <Slider
                                        min={1}
                                        max={12}
                                        step={0.5}
                                        onChange={(value) => { actionHandler("penWidthUpdated", value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        { /** BUTTONS  */}
                        <div className=' drawSignaturePadButtonsWrapper ' style={{ marginTop: "30px" }}>
                            <button
                                disabled={actionButtonsStatus === "disabled" ? true : false}
                                className='rbtn rbtn-primary rbtn-round rbtn-standard' type='button' onClick={() => { actionHandler("downloadSignature") }}>
                                    Download
                            </button>
                            <button
                                disabled={actionButtonsStatus === "disabled" ? true : false}
                                className='rbtn rbtn-primary-outline rbtn-round rbtn-standard' type='button' onClick={() => { actionHandler("clearCanvas") }} >
                                    Clear
                            </button>
                        </div>
                        </div>
                         
                    </div>

                </div>
            </div>
        </>
    );
}

export default DrawSignature;