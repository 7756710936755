const Welcome = () => {
    return (
        <>
            <div>
                <h1>WELCOME TO BunnyDoc Tools</h1>  
            </div>
        </>
    )
}

export default Welcome;