
import { react, useState } from 'react';
import ListSignatures from './components/ListAutoGraph';
import ColorPicker from '../ColorPicker';
import html2canvas from 'html2canvas';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

/**SLIDER START */
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import quotes from './components/Quote.json';
import constants from './components/constants';

const canvasCss = { border: "1px black solid" }





const AutoGraphV3 = () => {

    const [name, setName] = useState("James Bond");
    const [signatureSettings, setSignatureSettings] = useState({
        color: "#000000",
        fontSize: 60,
        fontWeight: 400,
        position: "right",
        fontCategory: "handwriting",
        fontFamily: "Slow Smoke"
    })
    const [quoteSettings, setQuoteSettings] = useState({
        color: "#000000",
        fontSize: 60,
        fontWeight: 400,
        position: "left",
        fontCategory: "handwriting",
        fontFamily: "Senja Santuy",
        addAuthor: true
    })

    const [containerSettings, setContainerSettings] = useState({
        backgroundColor: "transparent",
        borderColor: "transparent",
        width: "100%",
    });

    const [autographContainerBackgroundColor, setAutographContainerBackgroundColor] = useState("transparent");
    const [autographContainerBorderColor, setAutographContainerBorderColor] = useState("transparent");

    const [singleQuote, setSingleQuote] = useState({ quote: "The only way to do great work is to love what you do.", author: "Steve Jobs" });

    const [todayFeeling, setTodayFeeling] = useState("mature");

    const [tabKey, setTabKey] = useState('basicData');

    const handleSignatureColor = (color) => { setSignatureSettings({ ...signatureSettings, color: color.hex }); }
    const handleQuoteColor = (color) => { setQuoteSettings({ ...quoteSettings, color: color.hex }); }

    const handleAutoGraphContainerBackgroundColor = (color) => { setContainerSettings({ ...containerSettings, backgroundColor: color.hex }); }
    const handleAutoGraphContainerBorderColor = (color) => { setContainerSettings({ ...containerSettings, borderColor: color.hex }); }

    const DrawAutoGraph = () => {
        // console.log("quote ::", quote);

        //GET FONT FAMILY AND FONT FAMILY CLASS NAME FOR QUOTE
        let quoteFontFamily = quoteSettings["fontFamily"];
        let quoteFontFamilyClassNameRecord = quoteSettings["fontCategory"] !== "" && Array.isArray(constants.FONT_LIST[quoteSettings["fontCategory"]]) ? (constants.FONT_LIST[quoteSettings["fontCategory"]]).filter(singleFont => singleFont["fontname"] === quoteFontFamily) : undefined;
        let quoteFontFamilyClassName = 'senjaSantuyFontFamily';

        if (Array.isArray(quoteFontFamilyClassNameRecord) && quoteFontFamilyClassNameRecord.length > 0) {
            quoteFontFamilyClassName = quoteFontFamilyClassNameRecord[0]["className"];
        }


        //  SIGNATURE FONT FAMILY AND FONT FAMILY CLASS NAME
        let signatureFontFamily = signatureSettings["fontFamily"];
        let signatureFontFamilyClassNameRecord = (constants.FONT_LIST[signatureSettings["fontCategory"]]).filter(singleFont => singleFont["fontname"] === signatureFontFamily);
        let signatureFontFamilyClassName = 'badScriptFontFamily';

        if (Array.isArray(signatureFontFamilyClassNameRecord) && signatureFontFamilyClassNameRecord.length > 0) {
            signatureFontFamilyClassName = signatureFontFamilyClassNameRecord[0]["className"];
        }

        return (
            <>
                <div style={
                    {
                        display: "flex",
                        justifyContent: "center",
                        width: `${containerSettings["width"] == "100%" ? "100%" : containerSettings["width"] + "px"}`,

                    }
                }
                >

                    <div
                        id="autoGraphBox"
                        className="rcard rcard-body autoGraphCardBox"
                        key={"autoGraphCard"}
                        style={
                            {
                                transform: "rotate(0deg)",
                                backgroundColor: `${containerSettings["backgroundColor"] == "transparent" ? "unset" : containerSettings["backgroundColor"]}`,
                                borderColor: `${containerSettings["borderColor"] == "transparent" ? "transparent" : containerSettings["borderColor"]}`,
                                width: "100%"
                            }
                        }
                    >
                        <div className="autoGraphBox">
                            <div className={` quoteBox ${quoteFontFamilyClassName}`}
                                style={
                                    {
                                        color: `${quoteSettings["color"]}`,
                                        fontSize: `${quoteSettings["fontSize"]}px`,
                                        fontWeight: `${quoteSettings["fontWeight"]}`,
                                        textAlign: `${quoteSettings["position"]}`
                                    }
                                } >
                                {singleQuote["quote"]}

                                {
                                    quoteSettings["addAuthor"] && singleQuote["author"] != "" ? <span style={{ fontSize: "12px", display: "block", marginTop: "10px" }}> - {singleQuote["author"]} </span> : ""
                                }
                            </div>
                            <div className={` nameBox  ${signatureFontFamilyClassName}`} style={{ color: `${signatureSettings["color"]}`, fontSize: `${signatureSettings["fontSize"]}px`, fontWeight: `${signatureSettings["fontWeight"]}`, textAlign: `${signatureSettings["position"]}` }} > {name} </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }

    const convertDivIntoImage = () => {
        html2canvas(document.querySelector("#autoGraphBox"), { backgroundColor: "transparent" }).then(function (canvas) {
            //document.body.appendChild(canvas);

            //to make this image transaprent , add background: unset and backgroundCOlor : transparent to box with id autoGraphBox
            console.log(canvas.toDataURL());

            var a = document.createElement("a"); //Create <a>
            a.href = canvas.toDataURL(); //Image Base64 Goes here
            a.download = "Signature.png"; //File name Here
            a.click();

        });
    }


    //STEP 1
    const LoadBasicDetail = () => {
        return (<>
            {/** FEELINGS SELECTION   */}
            <div className='autoGraphActionsContainer' style={{ width: "100%", border: "unset" }}>
                <div className='mt-3' style={{ marginTop: "10px" }}>
                    <label className='autoGraphFieldLabel'>How are you feeling today?</label>

                    <div className="ai-categories-box">
                        <select
                            className='autoGraphselect'
                            onChange={(e) => { setTodayFeeling(e.target.value); }}>
                            {
                                Object.keys(constants.FEELINGS).map(singleKey => {
                                    return <option value={singleKey}>{constants.FEELINGS[singleKey]}</option>
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className='mt-3' style={{ marginTop: "10px" }}>
                    <label className='autoGraphFieldLabel'>Select Quote</label>

                    <div className="ai-categories-box">
                        <select
                            className='autoGraphselect'
                            onChange={(e) => {
                                console.log(e.target.value);
                                let quoteId = e.target.value;
                                let quoteRecord = quotes[todayFeeling].filter(singleQuote => singleQuote.id === quoteId);
                                if (Array.isArray(quoteRecord) && quoteRecord.length > 0) {
                                    setSingleQuote({
                                        quote: quoteRecord[0]["quote"],
                                        author: quoteRecord[0]["author"]
                                    })
                                }
                                else {
                                    setSingleQuote({
                                        quote: "",
                                        author: ""
                                    })
                                }
                            }}> 
                            {
                                quotes[todayFeeling].map(singleQuote => {
                                    return <option value={singleQuote.id}>{singleQuote.quote}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                {/**  ADD | REMOVE QUOTE AUTHOR */}
                <div className='mt-3 mb-3' style={{ marginTop: "10px" }}>
                    <div>
                        <label className='autoGraphFieldLabel'>Add Author - <span style={{ fontSize: "10px" }}> (Only Applicable if its is not a custom quote) </span></label>
                        <div style={{ display: "flex" }}>
                            <select
                                className='autoGraphselect'
                                onChange={(e) => {
                                    let addAuthor = e.target.value === "yes" ? true : false;
                                    setQuoteSettings({ ...quoteSettings, addAuthor: addAuthor });
                                }}>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                    </div>
                </div>

                {/**  QUOTE | SAYING FIELD */}
                <div className='mt-3 mb-3' style={{ marginTop: "10px" }}>
                    <div>
                        <label className='autoGraphFieldLabel'>Custom Quote | Saying (optional)</label>
                        <div style={{ display: "flex" }}>
                            <input type="text"
                                maxLength={200}
                                className='autoGraphBoxTextField'
                                placeholder='Custom Quote | Saying'
                                name="quoteInputField"
                                onChange={(e) => { setSingleQuote({ quote: e.target.value, author: "" }); }}
                            />
                        </div>
                    </div>
                </div>
                {/**NAME FIELD */}
                <div className='mt-1 mb-3 mt-3' style={{ marginTop: "10px" }}>
                    <label className='autoGraphFieldLabel'>Type Your Name</label>
                    <div style={{ display: "flex" }}>
                        <input type="text"
                            maxLength={25}
                            className='autoGraphBoxTextField'
                            placeholder='Type your name '
                            name="typeSignatureInputField"
                            value={name}
                            onChange={(e) => { setName(e.target.value); }}
                        />
                    </div>
                </div>

                {/** STYLING CONTAINER */}
                <div className='' style={{ marginTop: "1rem" }}>
                    <div className=''>
                        <label className='autoGraphActionsUBLabel'>Select container background</label>
                        <div className="" style={{ display: "flex" }}>
                            <ColorPicker penColor={autographContainerBackgroundColor} handlePenColor={handleAutoGraphContainerBackgroundColor} />
                        </div>
                        <button type="button" className="rbtn rbtn-primary rbtn-round rbtn-standard"
                            style={{ width: "140px", padding: "2px 6px 2px 6px", marginTop: "10px", fontSize: "12px", background: "gray", border: "unset", fontFamily: "cursive" }}
                            onClick={() => { setContainerSettings({ ...containerSettings, backgroundColor: "transparent" }); }}
                        >
                            Make it Transparent
                        </button>

                        <div className='flexBreakSmallScreen'></div>

                    </div>

                    <div className='' style={{ marginTop: "1rem" }}>
                        <label className='autoGraphActionsUBLabel'>Select container border background</label>
                        <div className="" style={{ display: "flex" }}>
                            <ColorPicker penColor={autographContainerBorderColor} handlePenColor={handleAutoGraphContainerBorderColor} />
                        </div>

                        <button type="button" className="rbtn rbtn-primary rbtn-round rbtn-standard"
                            style={{ width: "140px", padding: "2px 6px 2px 6px", marginTop: "10px", fontSize: "12px", background: "gray", border: "unset", fontFamily: "cursive" }}
                            onClick={() => { setContainerSettings({ ...containerSettings, borderColor: "transparent" }); }}
                        >
                            Make it Transparent
                        </button>
                        <div className='flexBreakSmallScreen'></div>
                    </div>

                    {/** CONTEINER WIDTH */}
                    <div className='mt-1 mb-3 mt-3' style={{ marginTop: "10px" }}>
                        <label className='autoGraphFieldLabel'>Enter the width of Autograph container in PX (e.g 500)  (optional)</label>
                        <div style={{ display: "flex" }}>
                            <input type="text"
                                maxLength={5}
                                className='autoGraphBoxTextField'
                                placeholder='500'
                                name="autographContainerWidth"
                                onChange={(e) => {
                                    let containerWidth = e.target.value;
                                    if (!isNaN(containerWidth) && containerWidth > 0) {
                                        setContainerSettings({ ...containerSettings, width: e.target.value });
                                    }
                                    else { setContainerSettings({ ...containerSettings, width: "100%" }); }

                                }}
                            />
                        </div>
                    </div>

                    <div>
                        <span style={{ fontWeight: "bold", fontSize: "12px", marginRight: "4px" }}>Note:</span>
                        <span style={{ fontSize: "12px" }}>
                            You're using quotes from our website, but please note that they're sourced from various authors. We've added the author details and encourage you to keep them intact when using the quotes. Thank you for respecting the original creators' work.
                        </span>
                    </div>

                </div>
            </div>
        </>)
    }

    //STEP 2
    const LoadQuoteStylingSection = () => {

        return (<>
            <div className='autoGraphActionsContainer' style={{ width: "100%", border: "unset" }}>
                <div className=''>
                    <label className='autoGraphActionsUBLabel'>Select text color</label>
                    <div className="" style={{ display: "flex" }}>
                        <ColorPicker penColor={quoteSettings["color"]} handlePenColor={handleQuoteColor} />
                    </div>
                    <div className='flexBreakSmallScreen'></div>
                </div>


                <div className=''>
                    <div className='sliderCss'>
                        <label className='autoGraphActionsUBLabel'>Font Size</label>
                        <Slider
                            defaultValue={quoteSettings["fontSize"]}
                            min={12}
                            max={80}
                            step={5}
                            onChange={(value) => { setQuoteSettings({ ...quoteSettings, fontSize: value }) }}
                        />
                    </div>
                </div>
                <div className=''>
                    <div className='sliderCss'>
                        <label className='autoGraphActionsUBLabel'>Font Weight</label>
                        <Slider
                            defaultValue={quoteSettings["fontWeight"]}
                            min={100}
                            max={1000}
                            step={100}
                            onChange={(value) => { setQuoteSettings({ ...quoteSettings, fontWeight: value }) }}
                        />
                    </div>
                </div>

                <div className=''>
                    <div className='dropdownCss'>
                        <label className='autoGraphActionsUBLabel'>Quote Position</label>
                        <select
                            value={quoteSettings["position"]}
                            onChange={(e) => { setQuoteSettings({ ...quoteSettings, position: e.target.value }) }}
                            className='autoGraphselect'
                        >
                            <option value="left">Left</option>
                            <option value="center">Center</option>
                            <option value="right">Right</option>
                        </select>
                    </div>
                </div>

                <div>

                    {/** FONT CATEGORY SELECTION **/}
                    <div className='mt-3' style={{ marginTop: "10px" }}>
                        <label className='autoGraphFieldLabel'>Select Font Category</label>

                        <div className="ai-categories-box">
                            <select
                                className='autoGraphselect'
                                value={quoteSettings["fontCategory"]}
                                onChange={(e) => { setQuoteSettings({ ...quoteSettings, fontCategory: e.target.value }) }}>
                                {
                                    Object.keys(constants.FONT_LIST).map(singleKey => {
                                        return <option value={singleKey}>{singleKey.charAt(0).toUpperCase() + singleKey.slice(1)}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    {/** FONT FAMILY SELECTION **/}
                    <div className='mt-3' style={{ marginTop: "10px" }}>
                        <label className='autoGraphFieldLabel'>Select Font Category</label>

                        <div className="ai-categories-box">
                            <select
                                className='autoGraphselect'
                                value={quoteSettings["fontFamily"]}
                                onChange={(e) => { setQuoteSettings({ ...quoteSettings, fontFamily: e.target.value }) }}>
                                {
                                    (constants.FONT_LIST[quoteSettings["fontCategory"]]).map(singleFont => {
                                        return <option value={singleFont["fontname"]}>{singleFont["fontname"]}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>

            </div>
        </>)
    }

    //STEP 3
    const LoadSignatureStylingSection = () => {
        return (<>

            <div className='autoGraphActionsContainer' style={{ width: "100%", border: "unset" }}>
                <label className='autoGraphActionsUBLabel'>Select text color</label>

                <div className=''>
                    <div className="" style={{ display: "flex" }}>
                        <ColorPicker penColor={signatureSettings["color"]} handlePenColor={handleSignatureColor} />
                    </div>
                    <div className='flexBreakSmallScreen'></div>
                </div>


                <div className=''>
                    <div className='sliderCss'>
                        <label className='autoGraphActionsUBLabel'>Font Size</label>
                        <Slider
                            defaultValue={signatureSettings["fontSize"]}
                            min={12}
                            max={80}
                            step={5}
                            onChange={(value) => { setSignatureSettings({ ...signatureSettings, fontSize: value }) }}
                        />
                    </div>
                </div>
                <div className=''>
                    <div className='sliderCss'>
                        <label className='autoGraphActionsUBLabel'>Font Weight</label>
                        <Slider
                            defaultValue={signatureSettings["fontWeight"]}
                            min={100}
                            max={1000}
                            step={100}
                            onChange={(value) => { setSignatureSettings({ ...signatureSettings, fontWeight: value }) }}
                        />
                    </div>
                </div>

                <div className=''>
                    <div className='dropdownCss'>
                        <label className='autoGraphActionsUBLabel'>Signature Position</label>
                        <select
                            value={signatureSettings["position"]}
                            onChange={(e) => { setSignatureSettings({ ...signatureSettings, position: e.target.value }) }}
                            className='autoGraphselect'
                        >
                            <option value="left">Left</option>
                            <option value="center">Center</option>
                            <option value="right">Right</option>
                        </select>
                    </div>
                </div>

                {/** FONT CATEGORY SELECTION **/}
                <div className='mt-3' style={{ marginTop: "10px" }}>
                    <label className='autoGraphFieldLabel'>Select Font Category</label>

                    <div className="ai-categories-box">
                        <select
                            className='autoGraphselect'
                            value={signatureSettings["fontCategory"]}
                            onChange={(e) => { setSignatureSettings({ ...signatureSettings, fontCategory: e.target.value }) }}>
                            {
                                Object.keys(constants.FONT_LIST).map(singleKey => {
                                    return <option value={singleKey}>{singleKey.charAt(0).toUpperCase() + singleKey.slice(1)}</option>
                                })
                            }
                        </select>
                    </div>
                </div>

                {/** FONT FAMILY SELECTION **/}
                <div className='mt-3' style={{ marginTop: "10px" }}>
                    <label className='autoGraphFieldLabel'>Select Font Family</label>

                    <div className="ai-categories-box">
                        <select
                            className='autoGraphselect'
                            value={signatureSettings["fontFamily"]}
                            onChange={(e) => { setSignatureSettings({ ...signatureSettings, fontFamily: e.target.value }) }}>
                            {
                                signatureSettings["fontCategory"] !== "" &&  Array.isArray(constants.FONT_LIST[signatureSettings["fontCategory"]]) ?
                                 (constants.FONT_LIST[signatureSettings["fontCategory"]]).map(singleFont => {
                                    return <option value={singleFont["fontname"]}>{singleFont["fontname"]}</option>
                                }) : ""
                            }
                        </select>
                    </div>
                </div>
            </div>

        </>)
    }

    return (
        <>
            {/**typeSignatureContainer */}
            <div className=''   >
                <div className='' style={{ display: "flex", flexWrap: "wrap" }}>
                    <div>
                        <div className='' style={{ width: "350px", left: "0" }} >
                            <div className='typeSignaturePadWrapper' style={{ padding: "0px", height: "100vh", overflowY: "auto", border: "1px solid rgb(247, 241, 241)" }}>

                                <div className='autoGraphContainer' style={{ border: "unset", paddingTop: "0px" }}>
                                    { /**********   start TAB  */}
                                    <Tabs
                                        id="controlled-tab-example"
                                        activeKey={tabKey}
                                        onSelect={(k) => setTabKey(k)}
                                        className="mb-3"
                                        fill
                                    >
                                        <Tab eventKey="basicData" title="Basic">
                                            {LoadBasicDetail()}

                                        </Tab>
                                        <Tab eventKey="quoteStyling" title="Quote">
                                            {/* QUOTE SETTINGS  */}
                                            {LoadQuoteStylingSection()}
                                        </Tab>
                                        <Tab eventKey="signature Styling" title="Signature">
                                            {/* SIGNATURE SETTINGS  */}
                                            {LoadSignatureStylingSection()}
                                        </Tab>
                                    </Tabs>

                                    { /**********   END TAB  */}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='typeSignatureContainer2' style={{ display: "flex", flexWrap: "wrap", width: "calc(100vw - 370px)" }}>
                        <div className='mt-5' style={{ width: "calc(100vw - 370px)", overflowX: "auto" }}>
                            <DrawAutoGraph />

                            <div style={{ textAlign: "center", marginTop: "20px" }}>
                                <button type="button" className="rbtn rbtn-primary rbtn-round rbtn-standard" onClick={() => { convertDivIntoImage() }}>Download </button>
                            </div>
                            
                        </div>
 

                    </div>

                </div>
            </div>
        </>
    );
}

export default AutoGraphV3;