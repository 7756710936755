//MORE FONTS ->https://google-webfonts-helper.herokuapp.com/fonts/allura?subsets=latin
const ListSignatures = ({ name, color , listingType, fontSize ,fontWeight, callback}) => {

    const fontList = [
        {"fontname" : "Bad Script" , "className" : "badScriptFontFamily"},
        {"fontname" : "Bilbo" , "className" : "bilboFontFamily"},
        {"fontname" : "Bilbo Swash Caps" , "className" : "bilboSwashFontFamily"},
        {"fontname" : "Dancing Script" , "className" : "dancingScriptFontFamily"},
        {"fontname" : "Moon Dance" , "className" : "moonDanceFontFamily"},
        {"fontname" : "Great Vibes" , "className" : "greatVibesFontFamily"},
        {"fontname" : "Sacramento" , "className" : "sacramentoFontFamily"},
        {"fontname" : "Alex Brush" , "className" : "alexBrushFontFamily"},
        {"fontname" : "Allura" , "className" : "alluraFontFamily"},
    ];

    console.log("fontList", Array.isArray(fontList));


    const downloadSignature = (fontName) => {
        console.log("DOWNLOAD SIGNATURE FOR FONT : " , fontName);
        var font = fontList.find(item => item.fontname > fontName ); 
        console.log("SELECTED FONT::", font);
        callback(fontName)
    }

    return (
        <>
            {
                fontList.map((font, key) => {
                    return (
                        <>
                            <div className= {listingType === "GRID" ? "col-md-6 col-sm-6 mb-5" : "col-sm-12 mb-5" }  key={key}>
                                <div className="rcard rcard-body typeSignatureCardBox"   key={key+"card"} style={{transform: "rotate(0deg)"}}  >
                                    <div className="typeSignatureBox">
                                        <div className={font["className"] + " nameBox "} style={{ color : `${color}`,  fontSize: `${fontSize}px` , fontWeight: `${fontWeight}`}} > {name} </div>
                                        <div> <button type="button" className="rbtn rbtn-primary rbtn-round rbtn-standard" onClick= { () => { downloadSignature(font["fontname"]) } }>Downlaod </button> </div>
                                    </div>
                                </div>
                            </div>

                        </>
                    )
                })
            }
        </>
    )

}

export default ListSignatures;