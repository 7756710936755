import { SketchPicker, ChromePicker, CustomPicker } from 'react-color';
import ReactDOM from 'react-dom'
import { useEffect, useRef, useState } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import Circle from 'react-color/lib/components/circle/Circle';
import { CgColorPicker } from "react-icons/cg";

const ColorPicker = ({ penColor, handlePenColor }) => {

    const { height, width } = useWindowDimensions();
    const [colorPickerOpen, setColorPickerOpen] = useState(false);
    const colorPickerPopupRef = useRef();
    const colorPickerButtonRef = useRef();
    const [colorPickerWrapperCss, setColorPickerWrapperCss] = useState({ "left": "0px", "top": "0px" });


    const handleColorPickerPosition = () => {
        console.log("colorPickerButtonRef.current.offsetTop", colorPickerButtonRef.current.offsetTop);
        setColorPickerWrapperCss({ "left": colorPickerButtonRef.current.offsetLeft + "px", "top": (colorPickerButtonRef.current.offsetTop - 240 > 0 ? colorPickerButtonRef.current.offsetTop - 240 : colorPickerButtonRef.current.offsetTop + 40) + "px" });
    }
    const handleColorPickerVisibility = () => {
        setColorPickerOpen(true);
        handleColorPickerPosition()
    }


    useEffect(() => {
        handleColorPickerPosition();
    }, [width, height])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (colorPickerPopupRef.current && !colorPickerPopupRef.current.contains(event.target)) {
                console.log("clicked outside ");
                setColorPickerOpen(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };


    }, [])

    return (
        <>
            <Circle
                colors={["#4caf50", "#f44336", "#000000", "#607d8b", "#2196f3"]}
                circleSize={25}
                onChange={handlePenColor}
                style={{ width: "100%" }}
            />
             

            <span>                
                <div  className='color-picker-icon' ref={colorPickerButtonRef}  onClick={handleColorPickerVisibility}>
                    <CgColorPicker style={{color: "black" }}    />
                </div>
            </span>


            <div className="colorPickerWrapper" style={{ position: "absolute", zIndex: "1", left: `${colorPickerWrapperCss.left}`, top: `${colorPickerWrapperCss.top}` }}>

                {colorPickerOpen ?
                    <div ref={colorPickerPopupRef}>
                        <ChromePicker
                            disableAlpha={true}
                            color={penColor}
                            onChange={handlePenColor}
                            onChangeComplete={handlePenColor}
                            presetColors={'#D0021B'}
                        />
                    </div>

                    : ""
                }
            </div>
        </>
    )
}


export default ColorPicker;