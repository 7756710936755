module.exports  ={
    FEELINGS : {
       "professional": "Professional", "entrepreneur": "Entrepreneur", "emotional": "Emotional", "mature": "Mature", "happy": "Happy", "sad": "Sad", "angry": "Angry", "excited": "Excited", "bored": "Bored", "tired": "Tired", "confused": "Confused", "stressed": "Stressed", "relaxed": "Relaxed", "anxious": "Anxious", "lonely": "Lonely", "calm": "Calm", "energetic": "Energetic", "motivated": "Motivated", "inspired": "Inspired", "hopeful": "Hopeful", "grateful": "Grateful", "loved": "Loved", "proud": "Proud", "peaceful": "Peaceful", "content": "Content", "optimistic": "Optimistic", "determined": "Determined", "indecisive": "Indecisive", "overwhelmed": "Overwhelmed", "insecure": "Insecure", "jealous": "Jealous", "guilty": "Guilty", "disappointed": "Disappointed", "frustrated": "Frustrated", "resentful": "Resentful", "embarrassed": "Embarrassed", "ashamed": "Ashamed", "regretful": "Regretful", "hurt": "Hurt", "scared": "Scared", "nervous": "Nervous", "worried": "Worried", "anxious": "Anxious", "terrified": "Terrified", "overwhelmed": "Overwhelmed", "hopeless": "Hopeless", "helpless": "Helpless", "lonely": "Lonely", "isolated": "Isolated", "abandoned": "Abandoned", "rejected": "Rejected", "depressed": "Depressed"
    },
    FONT_LIST : {
        "general": [
            { "fontname": "Bad Script", "className": "badScriptFontFamily" },
            { "fontname": "Bilbo", "className": "bilboFontFamily" },
            { "fontname": "Bilbo Swash Caps", "className": "bilboSwashFontFamily" },
            { "fontname": "Dancing Script", "className": "dancingScriptFontFamily" },
            { "fontname": "Moon Dance", "className": "moonDanceFontFamily" },
            { "fontname": "Great Vibes", "className": "greatVibesFontFamily" },
            { "fontname": "Sacramento", "className": "sacramentoFontFamily" },
            { "fontname": "Alex Brush", "className": "alexBrushFontFamily" },
            { "fontname": "Allura", "className": "alluraFontFamily" },
        ],
        "handwriting": [
            /** CATEGORY - handwriting */
            { "fontname": "Senja Santuy", "className": "senjaSantuyFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1 },
            { "fontname": "Hoarsely Single Line", "className": "hoarselySingleLineFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.5 },
            { "fontname": "Sansilk", "className": "sansilkFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Really Free", "className": "reallyFreeFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Free for Palestine", "className": "freeForPalestineFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Barokah Signature", "className": "barokahSignatureFontFamily", "fontSizeScale": 0.4, fontWeightScale: 1.0 },
            { "fontname": "Travel November", "className": "travelNovemberFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Sundiary", "className": "sundiaryFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Salonica", "className": "salonicaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Scriptina Pro", "className": "scriptinaProFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Rosabelia", "className": "rosabeliaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            // { "fontname": "Astagina Signature", "className": "astaginaSignatureFontFamily" , "fontSizeScale": 1.0, fontWeightScale: 1.0  },
            { "fontname": "Batfide Signature", "className": "batfideSignatureFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Rightman Signature", "className": "rightmanSignatureFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Slow Smoke", "className": "slowSmokeFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Endalmin", "className": "endalminFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],

        "cursive": [
            { "fontname": "Allura", "className": "alluraFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Sacramento", "className": "sacramentoFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "5th Grade Cursive", "className": "fifthGradeCursiveFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Great Vibes", "className": "greatVibesFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Adine Kirnberg", "className": "adineKirnbergFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Parisienne", "className": "parisienneFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Honey Script", "className": "honeyScriptFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Mrs Saint Delafield", "className": "mrsSaintDelafieldFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],
        "stylish": [
            { "fontname": "BrockScript", "className": "brockScriptFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "EuphoriaScript-Regular", "className": "euphoriaScriptFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "FlaemischeKanzleischrift", "className": "flaemischeKanzleischriftFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "gianna", "className": "giannaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Qwigley", "className": "qwigleyFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],
        /*"cool": [],*/
        "modern": [
            { "fontname": "Arizonia", "className": "arizoniaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Euphoria Script", "className": "euphoriaScriptFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Stalemate", "className": "stalemateFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Attentica 4F", "className": "attentica4FFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "League Script", "className": "leagueScriptFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],
        "fun": [
            { "fontname": "Penguin Attack", "className": "penguinAttackFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Minya Nouvelle", "className": "minyaNouvelleFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Butterfly Kids", "className": "butterflyKidsFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Mystery Quest", "className": "mysteryQuestFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Henny Penny", "className": "hennyPennyFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Life Savers", "className": "lifeSaversFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],
        "formal": [
            { "fontname": "Formal Script", "className": "formalScriptFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Parisienne", "className": "parisienneFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Calligraffitti", "className": "calligraffittiFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Sacramento", "className": "sacramentoFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Rochester", "className": "rochesterFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],
        "professional": [
            { "fontname": "Endalmin", "className": "endalminFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Allura", "className": "alluraFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "ChanticleerRomanNF", "className": "chanticleerRomanNFFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "engebrechtreRg", "className": "engebrechtreRgFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Flanella", "className": "flanellaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "MrBedfort-Regular", "className": "mrBedfortRegularFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Rhesmanisa", "className": "rhesmanisaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Bona Nova", "className": "bonaNovaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],
        "traditional": [
            { "fontname": "BantengStory", "className": "bantengStoryFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Famulred", "className": "famulredFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Italianno", "className": "italiannoFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Adusian Signature", "className": "adusianSignatureFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Parisienne", "className": "parisienneFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            //{ "fontname": "", "className": "", "fontSizeScale": 1.0, fontWeightScale: 1.0  },
        ],
    }
}