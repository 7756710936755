//MORE FONTS ->https://google-webfonts-helper.herokuapp.com/fonts/allura?subsets=latin
const ListSignatures = ({ category, name, color, listingType, fontSize, fontWeight, callback }) => {

    /*const fontList = [
        {"fontname" : "Bad Script" , "className" : "badScriptFontFamily"},
        {"fontname" : "Bilbo" , "className" : "bilboFontFamily"},
        {"fontname" : "Bilbo Swash Caps" , "className" : "bilboSwashFontFamily"},
        {"fontname" : "Dancing Script" , "className" : "dancingScriptFontFamily"},
        {"fontname" : "Moon Dance" , "className" : "moonDanceFontFamily"},
        {"fontname" : "Great Vibes" , "className" : "greatVibesFontFamily"},
        {"fontname" : "Sacramento" , "className" : "sacramentoFontFamily"},
        {"fontname" : "Alex Brush" , "className" : "alexBrushFontFamily"},
        {"fontname" : "Allura" , "className" : "alluraFontFamily"},
         CATEGORY - handwriting 
        {"fontname" : "Senja Santuy" , "className" : "senjaSantuyFontFamily"},
        {"fontname" : "Hoarsely Single Line" , "className" : "hoarselySingleLineFontFamily"},
        {"fontname" : "Sansilk" , "className" : "sansilkFontFamily"},
        {"fontname" : "Really Free" , "className" : "reallyFreeFontFamily"},
        {"fontname" : "Free for Palestine" , "className" : "freeForPalestineFontFamily"},
        {"fontname" : "Barokah Signature" , "className" : "barokahSignatureFontFamily"},
        {"fontname" : "Travel November" , "className" : "travelNovemberFontFamily"},
        {"fontname" : "Sundiary" , "className" : "sundiaryFontFamily"},
        {"fontname" : "Salonica" , "className" : "salonicaFontFamily"},
        {"fontname" : "Scriptina Pro" , "className" : "scriptinaProFontFamily"},
        {"fontname" : "Rosabelia" , "className" : "RosabeliaFontFamily"},
        {"fontname" : "Astagina Signature" , "className" : "astaginaSignatureFontFamily"},
        {"fontname" : "Batfide Signature" , "className" : "batfideSignatureFontFamily"},
        {"fontname" : "Rightman Signature" , "className" : "rightmanSignatureFontFamily"},
        {"fontname" : "Slow Smoke" , "className" : "slowSmokeFontFamily"},
        {"fontname" : "Endalmin" , "className" : "endalminFontFamily"},
    ];
    */
    const fontList = {
        "general": [
            { "fontname": "Bad Script", "className": "badScriptFontFamily" },
            { "fontname": "Bilbo", "className": "bilboFontFamily" },
            { "fontname": "Bilbo Swash Caps", "className": "bilboSwashFontFamily" },
            { "fontname": "Dancing Script", "className": "dancingScriptFontFamily" },
            { "fontname": "Moon Dance", "className": "moonDanceFontFamily" },
            { "fontname": "Great Vibes", "className": "greatVibesFontFamily" },
            { "fontname": "Sacramento", "className": "sacramentoFontFamily" },
            { "fontname": "Alex Brush", "className": "alexBrushFontFamily" },
            { "fontname": "Allura", "className": "alluraFontFamily" },
        ],
        "handwriting": [
            /** CATEGORY - handwriting */
            { "fontname": "Senja Santuy", "className": "senjaSantuyFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1 },
            { "fontname": "Hoarsely Single Line", "className": "hoarselySingleLineFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.5 },
            { "fontname": "Sansilk", "className": "sansilkFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Really Free", "className": "reallyFreeFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Free for Palestine", "className": "freeForPalestineFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Barokah Signature", "className": "barokahSignatureFontFamily", "fontSizeScale": 0.4, fontWeightScale: 1.0 },
            { "fontname": "Travel November", "className": "travelNovemberFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Sundiary", "className": "sundiaryFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Salonica", "className": "salonicaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Scriptina Pro", "className": "scriptinaProFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Rosabelia", "className": "rosabeliaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            // { "fontname": "Astagina Signature", "className": "astaginaSignatureFontFamily" , "fontSizeScale": 1.0, fontWeightScale: 1.0  },
            { "fontname": "Batfide Signature", "className": "batfideSignatureFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Rightman Signature", "className": "rightmanSignatureFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Slow Smoke", "className": "slowSmokeFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Endalmin", "className": "endalminFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],

        "cursive": [
            { "fontname": "Allura", "className": "alluraFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Sacramento", "className": "sacramentoFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "5th Grade Cursive", "className": "fifthGradeCursiveFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Great Vibes", "className": "greatVibesFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Adine Kirnberg", "className": "adineKirnbergFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Parisienne", "className": "parisienneFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Honey Script", "className": "honeyScriptFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Mrs Saint Delafield", "className": "mrsSaintDelafieldFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],
        "stylish": [
            { "fontname": "BrockScript", "className": "brockScriptFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "EuphoriaScript-Regular", "className": "euphoriaScriptFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "FlaemischeKanzleischrift", "className": "flaemischeKanzleischriftFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "gianna", "className": "giannaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Qwigley", "className": "qwigleyFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],
        "cool": [],
        "modern": [
            { "fontname": "Arizonia", "className": "arizoniaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Euphoria Script", "className": "euphoriaScriptFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Stalemate", "className": "stalemateFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Attentica 4F", "className": "attentica4FFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "League Script", "className": "leagueScriptFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],
        "fun": [
            { "fontname": "Penguin Attack", "className": "penguinAttackFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Minya Nouvelle", "className": "minyaNouvelleFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Butterfly Kids", "className": "butterflyKidsFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Mystery Quest", "className": "mysteryQuestFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Henny Penny", "className": "hennyPennyFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Life Savers", "className": "lifeSaversFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],
        "formal": [
            { "fontname": "Formal Script", "className": "formalScriptFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Parisienne", "className": "parisienneFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Calligraffitti", "className": "calligraffittiFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Sacramento", "className": "sacramentoFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Rochester", "className": "rochesterFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],
        "professional": [
            { "fontname": "Endalmin", "className": "endalminFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Allura", "className": "alluraFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "ChanticleerRomanNF", "className": "chanticleerRomanNFFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "engebrechtreRg", "className": "engebrechtreRgFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Flanella", "className": "flanellaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "MrBedfort-Regular", "className": "mrBedfortRegularFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Rhesmanisa", "className": "rhesmanisaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Bona Nova", "className": "bonaNovaFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
        ],
        "traditional": [
            { "fontname": "BantengStory", "className": "bantengStoryFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Famulred", "className": "famulredFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Italianno", "className": "italiannoFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Adusian Signature", "className": "adusianSignatureFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            { "fontname": "Parisienne", "className": "parisienneFontFamily", "fontSizeScale": 1.0, fontWeightScale: 1.0 },
            //{ "fontname": "", "className": "", "fontSizeScale": 1.0, fontWeightScale: 1.0  },
        ],
    };

    const downloadSignature = (fontName, fontSizeScale) => {
        callback(fontName, fontSizeScale)
    }

    return (
        <>
            {
                fontList[category] !== undefined && Array.isArray(fontList[category]) ? fontList[category].map((font, key) => {
                    return (
                        <>
                            <div className={listingType === "GRID" ? "col-md-6 col-sm-6 mb-5" : "col-sm-12 mb-5"} key={key}>
                                <div className="rcard rcard-body typeSignatureCardBox" key={key + "card"} style={{ transform: "rotate(0deg)" }}  >
                                    <div className="typeSignatureBox">
                                        <div className={font["className"] + " nameBox "} style={{ color: `${color}`, fontSize: `${fontSize * font["fontSizeScale"]}px`, fontWeight: `${fontWeight * font["fontWeightScale"]}` }} > {name} </div>
                                        <div> <button type="button" className="rbtn rbtn-primary rbtn-round rbtn-standard" onClick={() => { downloadSignature(font["fontname"], font["fontSizeScale"]) }}>Downlaod </button> </div>
                                    </div>
                                </div>
                            </div>

                        </>
                    )
                }) : ""
            }
        </>
    )

}

export default ListSignatures;